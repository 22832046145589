import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowPreviousIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M15.8542 5.73214C16.2585 5.2604 16.2039 4.55019 15.7321 4.14584C15.2604 3.74149 14.5502 3.79612 14.1458 4.26786L15.8542 5.73214ZM14.1458 19.7321C14.5502 20.2039 15.2604 20.2585 15.7321 19.8542C16.2039 19.4498 16.2585 18.7396 15.8542 18.2679L14.1458 19.7321ZM9 12L8.14584 11.2679L7.51829 12L8.14584 12.7321L9 12ZM14.1458 4.26786L8.14584 11.2679L9.85416 12.7321L15.8542 5.73214L14.1458 4.26786ZM8.14584 12.7321L14.1458 19.7321L15.8542 18.2679L9.85416 11.2679L8.14584 12.7321Z"
      fill="#F0F0F0"
    />
  </SvgIcon>
)
