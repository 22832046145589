import { SvgIcon, SvgIconProps } from '@mui/material'

export const VioletShape = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 135 183">
    <path
      d="M43.5845 0.501455C15.0906 -3.80726 3.2551 20.6723 0.850936 34.8122C-5.79317 96.5235 27.8163 159.957 54.8128 180.822C58.497 183.67 63.5946 183.251 67.1785 180.278C89.3863 161.858 87.4541 150.736 98.043 134.604C105.28 123.58 116.129 131.269 124.439 135.681C127.675 137.4 131.132 135.341 131.859 131.75C143.472 74.3532 120.139 27.5468 106.516 10.8603C101.496 5.24323 94.343 7.49028 89.1479 10.7906C85.9111 12.8468 82.8227 15.3894 79.0343 15.9828C71.4421 17.1719 64.769 14.0165 59.8981 10.0997C54.8974 6.07861 49.9292 1.46087 43.5845 0.501455Z"
      fill="#9E7BFF"
    />
  </SvgIcon>
)
