import { Stack } from '@mui/material'
import { isEmpty } from 'fp-ts/lib/Array'
import { IPublicArticlePreview } from 'src/api'
import { VioletShapeDesktop } from '../icons/article-violet-shape-desktop'
import { GreenShape } from '../icons/articles-green-shape'
import { PinkShape } from '../icons/articles-pink-shape'
import { VioletShape } from '../icons/articles-violet-shape'
import { FeaturedArticle } from './featured-article'

type Props = {
  articles: Array<IPublicArticlePreview>
}

export const FeaturedArticles = ({ articles }: Props) => {
  if (isEmpty(articles)) {
    return null
  }

  const firstArticle = articles[0]
  const secondArticle = articles[1]
  const thirdArticle = articles[2]

  if (
    firstArticle === undefined ||
    secondArticle === undefined ||
    thirdArticle === undefined
  ) {
    return null
  }

  return (
    <Stack
      spacing={{ xs: 1.5, sm: -2.25, lg: 0 }}
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ lg: 'space-between' }}
      height={{ lg: 437, xl: 472 }}
    >
      <FeaturedArticle
        shapeTitleSpacing={{ xs: 1.25, sm: 2, lg: 0.5, xl: -0.75 }}
        article={firstArticle}
        shape={
          <PinkShape
            sx={{
              fontSize: { xs: 177, sm: 233, lg: 308, xl: 350 },
              width: { xs: 144, sm: 190, lg: 245, xl: 275 },
            }}
          />
        }
        imageTopPosition={{ xs: 22, sm: 32.5, lg: 35, xl: 43 }}
        imageRightPosition={{ xs: 13, sm: 6.5, lg: 4, xl: 7 }}
        maxWidth={{ lg: 245, xl: 275 }}
      />
      <FeaturedArticle
        isOppositeDirection
        article={secondArticle}
        shapeTitleSpacing={{ xs: 0.625, sm: 2, lg: -0.75, xl: -1.625 }}
        shape={
          <GreenShape
            sx={{
              fontSize: { xs: 169, sm: 239, lg: 316, xl: 350 },
              width: { xs: 142, sm: 205, lg: 271, xl: 296 },
            }}
          />
        }
        imageTopPosition={{ xs: 21, sm: 28, lg: 33, xl: 36 }}
        imageRightPosition={{ xs: 11, sm: 15, lg: 16, xl: 14 }}
        pl={{ lg: 1.875, xl: 1.5 }}
        maxWidth={{ lg: 271, xl: 296 }}
      />
      <FeaturedArticle
        article={thirdArticle}
        shapeTitleSpacing={{ xs: 1.375, sm: 2, lg: -0.75, xl: -0.375 }}
        shape={
          <>
            <VioletShape
              sx={{
                fontSize: { xs: 185, sm: 260 },
                width: { xs: 135, sm: 190 },
                display: { xs: 'block', lg: 'none' },
              }}
            />
            <VioletShapeDesktop
              sx={{
                fontSize: { lg: 327, xl: 347 },
                width: { lg: 260, xl: 288 },
                display: { xs: 'none', lg: 'block' },
              }}
            />
          </>
        }
        imageTopPosition={{ xs: 27, sm: 36, lg: 41, xl: 43 }}
        imageRightPosition={{ xs: 5, sm: 6.5, lg: 11, xl: 12 }}
        mt={{ sm: -4.5 }}
        maxWidth={{ lg: 260, xl: 288 }}
      />
    </Stack>
  )
}
