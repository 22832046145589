import { Button, Skeleton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useTranslation from 'next-translate/useTranslation'
import elements from '../icons/elements.svg'
import { isEmpty } from 'fp-ts/lib/Array'
import { EventsNearbyType, IEventsNearby } from 'src/api'
import {
  eventCardSpacing,
  sectionHeaderContentSpacing,
} from 'src/layouts/spacing'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'
import { EventCardFindTickets } from 'src/modules/event/card'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'

type Props = {
  eventsNearby: IEventsNearby
  isCoordinatesLoading: boolean
}

export const EventsNearby = ({ eventsNearby, isCoordinatesLoading }: Props) => {
  const { t } = useTranslation()
  const events = eventsNearby.content

  if (isEmpty(events)) {
    return null
  }

  return (
    <Box position="relative">
      <Box
        sx={{ mx: -2 }}
        display={{ xs: 'block', sm: 'none' }}
        position="absolute"
        top={-21}
        left={0}
        right={0}
        zIndex={-1}
      >
        {/*eslint-disable-next-line @next/next/no-img-element*/}
        <img
          src={elements}
          style={{ objectFit: 'fill', height: '460px', width: '100%' }}
          alt="events-elements"
        />
      </Box>
      <Box>
        {isCoordinatesLoading ? (
          <Stack spacing={{ xs: 2, lg: 4 }}>
            <Skeleton
              variant="rectangular"
              sx={{
                height: { xs: 32, sm: 36, lg: 40, xl: 58 },
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                height: { xs: 419, sm: 423, lg: 447, xl: 466 },
              }}
            />
          </Stack>
        ) : (
          <Stack spacing={{ xs: 2.25, sm: 3, xl: 4 }}>
            <Stack spacing={sectionHeaderContentSpacing}>
              <Typography variant="h2Black">
                {eventsNearby.type === EventsNearbyType.NEARBY
                  ? t('events_near_you')
                  : t('popular_events')}
              </Typography>
              <Box>
                <Stack
                  sx={scrollableRowStyles}
                  direction="row"
                  spacing={eventCardSpacing}
                >
                  {events.map((event) => (
                    <EventCardFindTickets event={event} key={event.eventId} />
                  ))}
                </Stack>
              </Box>
            </Stack>
            <NextLink href={pages['/events']} underline="none">
              <Button variant="secondary" size="medium" fullWidth>
                {t('see_all_events')}
              </Button>
            </NextLink>
          </Stack>
        )}
      </Box>
    </Box>
  )
}
