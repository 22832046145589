import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowNextIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M8.14584 5.73214C7.74149 5.2604 7.79612 4.55019 8.26786 4.14584C8.7396 3.74149 9.44981 3.79612 9.85416 4.26786L8.14584 5.73214ZM9.85416 19.7321C9.44981 20.2039 8.7396 20.2585 8.26786 19.8542C7.79612 19.4498 7.74149 18.7396 8.14584 18.2679L9.85416 19.7321ZM15 12L15.8542 11.2679L16.4817 12L15.8542 12.7321L15 12ZM9.85416 4.26786L15.8542 11.2679L14.1458 12.7321L8.14584 5.73214L9.85416 4.26786ZM15.8542 12.7321L9.85416 19.7321L8.14584 18.2679L14.1458 11.2679L15.8542 12.7321Z"
      fill="#F0F0F0"
    />
  </SvgIcon>
)
