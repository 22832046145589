import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
} from '@mui/material'
import { Box } from '@mui/system'
import { Carousel } from 'react-responsive-carousel'
import { ArrowNextIcon } from '../icons/arrow-next'
import { ArrowPreviousIcon } from '../icons/arrow-previous'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { EventPlace, IEvent } from 'src/api'
import { useLocale } from 'src/lib/use-locale'
import { CircleIndicatorIcon } from 'src/assets/icons'
import { pages } from 'src/app/pages'
import {
  ResponsiveImage,
  getHeaderImageSrcSet,
  getLogoSrcSet,
} from 'src/components/responsive-image'
import { formatEventDateWithoutTime } from 'src/modules/event/utils/event-format-date'
import { isSome } from 'fp-ts/lib/Option'
import { ExploreButton } from 'src/components/explore-button'

type Props = {
  banner: Array<IEvent>
}

export const Banner = ({ banner }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { locale } = useLocale()

  const showCarouselControls = banner.length > 1 ? true : false

  const bannerContentBottomPosition = showCarouselControls
    ? { xs: 44, sm: 56, lg: 54 }
    : { xs: 20, sm: 24, lg: 28 }

  const indicatorHeight = { xs: '8px', sm: '10px' }
  const arrowSize = { lg: '28px', xl: '40px' }
  const logoProportion = { xs: 73, sm: 80, lg: 96 }

  return (
    <Box
      sx={{
        '&& .carousel-slider': {
          borderRadius: { xs: '16px' },
          zIndex: 0,
        },
        '&& .control-dots': {
          lineHeight: indicatorHeight,
          marginBottom: { xs: 2, sm: 3, lg: 2.5 },
        },
      }}
    >
      <Carousel
        autoPlay
        interval={60_000}
        showThumbs={false}
        showStatus={false}
        showIndicators={showCarouselControls}
        infiniteLoop
        renderIndicator={(clickHandler, isSelected) => {
          return (
            <Box px={isSelected ? '5px' : 0} display="inline-flex">
              <Box
                sx={{
                  p: '2px 5px',
                  ...(isSelected && {
                    borderRadius: '50%',
                    p: '2px',
                    border: '1px solid',
                    borderColor: 'secondary6.main',
                    m: '-1px',
                    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1))',
                  }),
                }}
                display="inline-flex"
              >
                <IconButton
                  onClick={clickHandler}
                  sx={{
                    p: 0,
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CircleIndicatorIcon sx={{ fontSize: indicatorHeight }} />
                </IconButton>
              </Box>
            </Box>
          )
        }}
        renderArrowNext={(clickHandler) =>
          isSmallDesktop &&
          showCarouselControls && (
            <IconButton
              onClick={clickHandler}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                p: 0,
                transform: 'translate(0, -50%)',
                zIndex: 3,
                height: '100%',
                minWidth: '50px',
                borderRadius: 0,
              }}
            >
              <ArrowNextIcon sx={{ fontSize: arrowSize }} />
            </IconButton>
          )
        }
        renderArrowPrev={(clickHandler) =>
          isSmallDesktop &&
          showCarouselControls && (
            <IconButton
              onClick={clickHandler}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                p: 0,
                transform: 'translate(0, -50%)',
                zIndex: 3,
                height: '100%',
                borderRadius: 0,
                minWidth: '50px',
              }}
            >
              <ArrowPreviousIcon sx={{ fontSize: arrowSize }} />
            </IconButton>
          )
        }
      >
        {banner.map((event) => {
          return (
            <Box
              key={event.eventId}
              height={{ xs: 356, sm: 461, lg: 472, xl: 526 }}
              sx={{
                position: 'relative',
                '& .mainImage': {
                  zIndex: 1,
                },
              }}
            >
              <Box
                position="absolute"
                width="100%"
                height="100%"
                sx={{
                  background:
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 79.7%)',
                  zIndex: 2,
                }}
              />
              {isSome(event.logoSetting) && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 3,
                    width: logoProportion,
                    height: logoProportion,
                    left: { xs: 16, sm: 'auto' },
                    top: { xs: 16, sm: 18, lg: 24 },
                    right: { sm: 18, lg: 24 },
                    '& .eventLogo': {
                      borderRadius: '50%',
                      backgroundColor:
                        event.logoSetting.value.logoBackgroundColor,
                    },
                  }}
                >
                  <ResponsiveImage
                    className="eventLogo"
                    srcSet={getLogoSrcSet(
                      event.logoSetting.value.logo.resizedUrls,
                    )}
                    alt={event.name}
                    originalSizeUrl={event.logoSetting.value.logo.url}
                  />
                </Box>
              )}
              <Stack
                position="absolute"
                width="100%"
                px={{ xs: 1.5, sm: 2, lg: 3, xl: 10 }}
                sx={{ textAlign: 'center', zIndex: 3, color: 'common.white' }}
                left={0}
                bottom={bannerContentBottomPosition}
                spacing={{ xs: 2, sm: 1.5 }}
              >
                <Stack spacing={{ xs: 0.5, sm: 1 }} alignItems="center">
                  <Typography variant="h2Black" component="div">
                    {event.name}
                  </Typography>
                  <Stack spacing={{ xs: 0.5 }} direction={{ sm: 'row' }}>
                    <Typography variant="h4" component="div">
                      {formatEventDateWithoutTime({
                        eventSchedules: event.eventSchedules,
                        locale,
                        timeframe: event.timeframe,
                      })}
                    </Typography>
                    <Typography variant="h4" component="div">
                      {t('in_place', {
                        place:
                          event.eventPlace.placeType ===
                          EventPlace.TO_BE_ANNOUNCED
                            ? event.eventPlace.announcedCity
                            : event.eventPlace.venue.city,
                      })}
                    </Typography>
                  </Stack>
                </Stack>
                <Link
                  passHref
                  href={pages['/events/[eventSlug]']({
                    eventSlug: event.eventSlug,
                  })}
                  legacyBehavior
                >
                  <ExploreButton
                    label={t('find_tickets')}
                    component={MuiLink}
                    sx={{ alignSelf: 'center' }}
                  />
                </Link>
              </Stack>
              <ResponsiveImage
                alt={event.name}
                originalSizeUrl={event.mainImage.url}
                srcSet={getHeaderImageSrcSet(event.mainImage.resizedUrls)}
                className="mainImage"
              />
            </Box>
          )
        })}
      </Carousel>
    </Box>
  )
}
