import { Button, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { isEmpty, isNonEmpty } from 'fp-ts/lib/Array'
import useTranslation from 'next-translate/useTranslation'
import {
  IPlace,
  IPlaceWithDistance,
  IPlaceWithEvents,
  PlacePayloadType,
} from 'src/api'
import { pages } from 'src/app/pages'
import { NextLink } from 'src/components/next-link'
import { Place } from './place'
import { Places } from './places'

type Props = {
  places: Array<IPlace>
  isCoordinatesLoading: boolean
}

const closeToYouDistance = 5 as const

export const PlacesSection = ({ places, isCoordinatesLoading }: Props) => {
  const { t } = useTranslation()

  if (isEmpty(places)) {
    return null
  }

  if (isCoordinatesLoading) {
    return (
      <Stack spacing={{ xs: 2, lg: 4 }}>
        <Skeleton
          variant="rectangular"
          sx={{
            height: { xs: 32, sm: 36, lg: 40, xl: 58 },
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: { xs: 224, lg: 240, xl: 272 },
          }}
        />
      </Stack>
    )
  }

  const placesWithDistance = places.filter(
    (place) => place.payload.payloadType === PlacePayloadType.WITH_DISTANCE,
  ) as Array<IPlaceWithDistance>
  const placesWithEvents = places.filter(
    (place) => place.payload.payloadType === PlacePayloadType.WITH_EVENT_COUNT,
  ) as Array<IPlaceWithEvents>

  const contentSpacing = { xs: 2.25, lg: 3, xl: 4 }

  const buttonSpacing = { xs: 2.25, sm: 3, lg: 4 }
  const buttonSeeAll = (
    <NextLink href={pages['/places']} underline="none">
      <Button variant="secondary" size="medium" fullWidth>
        {t('see_all_places')}
      </Button>
    </NextLink>
  )

  if (isNonEmpty(placesWithDistance)) {
    return (
      <Stack spacing={buttonSpacing}>
        <Stack spacing={contentSpacing}>
          <Stack spacing={{ xs: 0.25, lg: 0.5 }}>
            <Typography variant="h2Black">{t('close_to_you')}</Typography>
            <Typography variant="bodyRegular">
              {t('search_by_location_nearest_events')}
            </Typography>
          </Stack>
          <Places
            places={placesWithDistance}
            renderPlace={(place) => (
              <Place
                resizedImageUrls={place.image.resizedUrls}
                key={place.id}
                placeSlug={place.placeSlug}
                name={place.name}
                imageUrl={place.image.url}
                descriptionText={
                  place.payload.distance < closeToYouDistance
                    ? t('close_to_you_dot')
                    : t('km_drive_from_you', {
                        km: place.payload.distance,
                      })
                }
              />
            )}
          />
        </Stack>
        {buttonSeeAll}
      </Stack>
    )
  }

  if (isNonEmpty(placesWithEvents)) {
    return (
      <Stack spacing={buttonSpacing}>
        <Stack spacing={contentSpacing}>
          <Typography variant="h2Black">{t('popular_places')}</Typography>
          <Places
            places={placesWithEvents}
            renderPlace={(place) => (
              <Place
                key={place.id}
                placeSlug={place.placeSlug}
                name={place.name}
                imageUrl={place.image.url}
                descriptionText={t('events_happening_here', {
                  events: place.payload.eventCount,
                })}
                resizedImageUrls={place.image.resizedUrls}
              />
            )}
          />
        </Stack>
        {buttonSeeAll}
      </Stack>
    )
  }

  return null
}
