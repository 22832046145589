import { SvgIcon, SvgIconProps } from '@mui/material'

export const VioletShapeDesktop = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 260 323">
    <path
      d="M81.9254 0.686837C28.5041 -6.19873 6.212 40.3158 1.63884 67.3243C-11.0327 185.508 52.3197 281.094 104.044 318.324C112.111 324.13 122.901 323.162 130.897 317.259C172.066 286.871 168.674 291.138 188.824 260.315C202.425 239.508 222.655 253.17 238.496 261.771C245.354 265.495 252.693 261.187 254.202 253.531C275.975 143.107 231.28 53.151 205.142 21.0031C195.789 10.4951 182.594 14.2178 172.685 20.2493C165.707 24.497 159.143 30.0429 151.04 31.0768C137.69 32.78 125.874 27.5438 116.912 20.7472C106.025 12.4913 95.4762 2.43342 81.9254 0.686837Z"
      fill="#9E7BFF"
    />
  </SvgIcon>
)
