import { Typography, Stack } from '@mui/material'
import { useQuery } from 'react-query'
import useTranslation from 'next-translate/useTranslation'
import { Banner } from './events/banner'
import { PlacesSection } from './places/places-section'
import { EventsNearby } from './events/events-nearby'
import { RecommendedEvents } from './events/recommended-events'
import { FeaturedArticles } from './articles/featured-articles'
import { NextSeo } from 'next-seo'
import {
  getEventsNearby,
  getExploreBanner,
  getExplorePlaces,
  getFeaturedArticles,
  getRecommendedEvents,
  IEvent,
  IEventsNearby,
  IPlace,
  IPublicArticlePreview,
  qk,
} from 'src/api'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { useLocale } from 'src/lib/use-locale'
import {
  UserCoordinatesState,
  useUserGeolocation,
} from 'src/lib/use-user-geolocation'
import { globalAccessToken } from 'src/lib/use-auth'
import { getAbsoluteUrlWithLocale } from 'src/lib/get-path-with-locale'
import { pages } from 'src/app/pages'
import {
  HeaderGradient,
  HeaderGradientElements,
} from 'src/layouts/header-gradient'
import { exploreHeaderGradient } from 'src/assets/gradients'
import { env } from 'src/app/env/env'

export type ExplorePageProps = {
  banner: Array<IEvent>
  places: Array<IPlace>
  eventsNearby: IEventsNearby
  featuredArticles: Array<IPublicArticlePreview>
}

export const ExplorePage = ({
  banner,
  places,
  eventsNearby,
  featuredArticles,
}: ExplorePageProps) => {
  const isSessionLoaded = useIsSessionLoaded()
  const { t } = useTranslation()
  const { locale } = useLocale()

  const $banner = useQuery(
    qk.cms.public.explore.banner.toKeyWithArgs({ locale }),
    () => getExploreBanner({ locale }),
    { initialData: banner, refetchOnWindowFocus: false, refetchOnMount: false },
  )

  const { userCoordinates, userCoordinatesStatus } = useUserGeolocation()

  // TODO: change initialData with prefetchQuery
  const $places = useQuery(
    qk.cms.public.explore.places.toKeyWithArgs({
      coordinates: userCoordinates,
      locale,
    }),
    () => getExplorePlaces({ coordinates: userCoordinates, locale }),
    {
      initialData: places,
      refetchOnWindowFocus: false,
    },
  )

  const $eventsNearby = useQuery(
    qk.cms.public.explore.events.nearby.toKeyWithArgs({
      coordinates: userCoordinates,
      locale,
    }),
    () => getEventsNearby({ coordinates: userCoordinates, locale }),
    {
      initialData: eventsNearby,
      refetchOnWindowFocus: false,
      enabled: isSessionLoaded,
    },
  )

  const $recommendedEvents = useQuery(
    qk.cms.public.explore.events.recommended.toKey(),
    () => getRecommendedEvents({ locale }),
    {
      enabled: globalAccessToken !== null,
      refetchOnWindowFocus: false,
    },
  )

  const $featuredArticles = useQuery(
    qk.content.public.explore.article.featured.toKeyWithArgs({ locale }),
    () => getFeaturedArticles({ locale }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      initialData: featuredArticles,
    },
  )

  if (
    $banner.data === undefined ||
    $places.data === undefined ||
    $eventsNearby.data === undefined ||
    $featuredArticles.data === undefined
  ) {
    return null
  }

  return (
    <>
      <NextSeo
        description={t('explore_discover_buy_tickets')}
        openGraph={{
          type: 'website',
          url: getAbsoluteUrlWithLocale({
            locale,
            path: pages['/'],
          }),
          images: [{ url: `${env.originUrl}/main.webp` }],
        }}
        defaultTitle={t('pages./')}
      />
      <HeaderGradient
        gradient={exploreHeaderGradient}
        gradientHeight={{ xs: '280px', sm: '354px', lg: '436px', xl: '540px' }}
        decors={<HeaderGradientElements />}
      >
        <Stack spacing={{ xs: 7, lg: 8, xl: 9 }}>
          <Stack spacing={{ xs: 8, sm: 7, xl: 9 }}>
            <Stack spacing={{ xs: 7, lg: 8, xl: 9 }}>
              <Stack spacing={{ xs: 7, lg: 8, xl: 9 }}>
                <Stack spacing={{ xs: 5, lg: 7, xl: 8 }}>
                  <Stack spacing={{ xs: 3, sm: 3.5, lg: 4 }}>
                    <Stack spacing={1} sx={{ color: 'common.white' }}>
                      <Typography
                        variant="h1"
                        component="h1"
                        color="accent2.main"
                      >
                        {t('magical_moments')}
                      </Typography>
                      <Typography
                        variant="excerpt"
                        sx={{ maxWidth: { xl: 679 } }}
                      >
                        {t('find_memories_for_life_description')}
                      </Typography>
                    </Stack>
                    <Banner banner={$banner.data} />
                  </Stack>
                  <EventsNearby
                    eventsNearby={$eventsNearby.data}
                    isCoordinatesLoading={
                      ($eventsNearby.isRefetching &&
                        userCoordinates !== null) ||
                      userCoordinatesStatus === UserCoordinatesState.loading
                    }
                  />
                </Stack>
                <PlacesSection
                  places={$places.data}
                  isCoordinatesLoading={
                    ($places.isRefetching && userCoordinates !== null) ||
                    userCoordinatesStatus === UserCoordinatesState.loading
                  }
                />
              </Stack>
              <FeaturedArticles articles={$featuredArticles.data} />
            </Stack>
          </Stack>
          <RecommendedEvents events={$recommendedEvents.data ?? []} />
        </Stack>
      </HeaderGradient>
    </>
  )
}
