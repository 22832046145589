import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { ResponsiveStyleValue } from '@mui/system'
import { IPublicArticlePreview } from 'src/api'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { ResponsiveImage } from 'src/components/responsive-image'

type Props = {
  article: IPublicArticlePreview
  shape: ReactNode
  isOppositeDirection?: boolean
  shapeTitleSpacing: ResponsiveStyleValue<number | string>
  imageTopPosition: ResponsiveStyleValue<number>
  imageRightPosition: ResponsiveStyleValue<number>
  mt?: ResponsiveStyleValue<number>
  pl?: ResponsiveStyleValue<number>
  maxWidth: ResponsiveStyleValue<number>
}

export const FeaturedArticle = ({
  article,
  shape,
  isOppositeDirection,
  shapeTitleSpacing,
  imageRightPosition,
  imageTopPosition,
  mt,
  pl,
  maxWidth,
}: Props) => {
  return (
    <Stack
      direction={{ xs: 'row' }}
      justifyContent={{
        xs: isOppositeDirection ? 'flex-start' : 'flex-end',
        sm: isOppositeDirection ? 'flex-end' : 'flex-start',
        lg: 'center',
      }}
      alignSelf={{ lg: isOppositeDirection ? 'flex-start' : 'flex-end' }}
      pl={pl}
      sx={{
        '&&': {
          mt,
        },
      }}
      maxWidth={maxWidth}
    >
      <NextLink
        href={pages['/articles/[articleSlug]']({
          articleSlug: article.articleSlug,
        })}
        color="common.black"
        underline="none"
      >
        <Stack
          direction={{
            xs: isOppositeDirection ? 'row-reverse' : 'row',
            sm: isOppositeDirection ? 'row' : 'row-reverse',
            lg: 'column-reverse',
          }}
          spacing={shapeTitleSpacing}
        >
          <Stack
            spacing={{ xs: 0.5 }}
            textAlign={{
              xs: isOppositeDirection ? 'left' : 'right',
              sm: isOppositeDirection ? 'right' : 'left',
              lg: 'center',
            }}
            alignSelf="center"
            sx={{ zIndex: 1 }}
          >
            <Typography variant="bodyRegular" component="div">
              {article.categories[0].name}
            </Typography>
            <Typography variant="h3Bold" component="div">
              {article.title}
            </Typography>
          </Stack>
          <Stack position="relative" flexShrink={0}>
            {shape}
            <Box
              height={{ xs: 135, sm: 190, lg: 254, xl: 285 }}
              width={{ xs: 125, sm: 176, lg: 236, xl: 266 }}
              sx={{
                flexShrink: 0,
                '& .mainImage': {
                  borderRadius: {
                    xs: '6px',
                    sm: '12px',
                  },
                },
                top: imageTopPosition,
                right: imageRightPosition,
              }}
              position="absolute"
            >
              <ResponsiveImage
                alt={article.title}
                originalSizeUrl={article.image.url}
                srcSet={{
                  MOBILE: article.image.resizedUrls.SMALL,
                  TABLET: article.image.resizedUrls.MEDIUM,
                }}
                className="mainImage"
              />
            </Box>
          </Stack>
        </Stack>
      </NextLink>
    </Stack>
  )
}
