import { SvgIcon, SvgIconProps } from '@mui/material'

export const GreenShape = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 142 169">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.0818 0.141012C99.8215 -2.24634 98.2881 26.1688 114.433 48.2941C129.793 69.3429 147.821 93.4229 140.192 118.569C129.558 142.853 123.489 148.191 100.666 157.322C81.1567 165.127 60.9488 173.879 41.5644 165.751C20.8207 157.052 4.14169 138.109 0.485931 115.313C-2.9707 93.7586 12.7512 76.3796 24.295 58.0656C38.5162 35.504 47.0973 2.46093 73.0818 0.141012Z"
      fill="#CCF6E8"
    />
  </SvgIcon>
)
