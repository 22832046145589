import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { ReactNode } from 'react'
import { IPlace } from 'src/api'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'

const rows = 2 as const

type Props<Place extends IPlace> = {
  places: Array<Place>
  renderPlace: (place: Place) => ReactNode
}

export const Places = <Place extends IPlace>({
  places,
  renderPlace,
}: Props<Place>) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  if (isDesktop) {
    return (
      <Box>
        <Grid container rowSpacing={4} columnSpacing={{ lg: 8.25, xl: 8.125 }}>
          {places.map((place) => (
            <Grid item xs={4} key={place.id}>
              {renderPlace(place)}
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  const columns = Math.ceil(places.length / rows)

  return (
    <Box>
      <Stack
        sx={scrollableRowStyles}
        spacing={{ xs: 2.25, sm: 2.5 }}
        direction="row"
      >
        {places.map((_place, index) => {
          if (index >= columns) {
            return null
          }

          const columnIndex = index * rows

          const columnRows = Array.from({ length: rows }).map((_row, index) => {
            const place = places[columnIndex + index]

            if (place === undefined) {
              return null
            }

            return renderPlace(place)
          })

          return (
            <Stack spacing={{ xs: 2 }} key={index}>
              {columnRows}
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}
