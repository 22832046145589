import { Box } from '@mui/material'
import { CurveLine, Dot } from 'src/assets/icons'

export const HeaderGradientElements = () => {
  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '20px', lg: '57px' },
          left: { sm: '21px', lg: '42px', xl: '66px' },
        }}
      >
        <Dot
          sx={{ color: 'secondary1.main', fontSize: { sm: 6, lg: 8, xl: 10 } }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '167px', lg: '224px', xl: '247px' },
          left: { sm: '12px', lg: '85px', xl: '146px' },
        }}
      >
        <Dot sx={{ color: 'accent1.main', fontSize: { sm: 6, xl: 9 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '122px', lg: '233px', xl: '257px' },
          right: { sm: '36px', lg: '91px', xl: '75px' },
        }}
      >
        <Dot
          sx={{ color: 'accent1.main', fontSize: { sm: 8, lg: 10, xl: 14 } }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '37px', lg: '369px', xl: '474px' },
          right: { sm: '125px', lg: '59px', xl: '56px' },
        }}
      >
        <Dot sx={{ color: 'accent2.main', fontSize: { sm: 8, xl: 10 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '263px', lg: '350px', xl: '437px' },
          left: { sm: '-10px', lg: '26px', xl: '30px' },
        }}
      >
        <CurveLine
          sx={{
            color: 'secondary2.main',
            fontSize: { sm: 27, xl: 50 },
            transform: 'rotate(60deg)',
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { sm: '28px', lg: '2px' },
          right: { sm: '-5px', lg: '-8px', xl: '-10px' },
        }}
      >
        <CurveLine
          sx={{ color: 'accent2.main', fontSize: { sm: 26, lg: 48 } }}
        />
      </Box>
    </Box>
  )
}
