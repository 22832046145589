import { SvgIcon, SvgIconProps } from '@mui/material'

export const PinkShape = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 144 177">
    <path
      d="M37.3976 13.5025C6.30356 50.1236 -0.473351 97.4259 0.0249514 116.499C-0.01686 121.971 0.00454499 132.556 7.4996 132.423C11.461 132.354 21.9504 116.499 37.3976 148.843C49.7554 174.718 69.4551 178.201 77.7602 176.707C132.075 170.239 149.516 45.348 142.54 15.4938C136.959 -8.38951 116.296 0.566057 106.662 8.02926C75.742 27.8639 79.7284 15.9222 65.3027 8.02926C52.1986 0.859393 42.3807 7.19997 37.3976 13.5025Z"
      fill="#FFCFCF"
    />
  </SvgIcon>
)
