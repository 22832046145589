import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { IImageResizedUrls } from 'src/api'
import { pages } from 'src/app/pages'
import { NextLink } from 'src/components/next-link'
import { ResponsiveImage } from 'src/components/responsive-image'

type Props = {
  imageUrl: string
  name: string
  descriptionText: string
  placeSlug: string
  resizedImageUrls: IImageResizedUrls
}

export const Place = ({
  imageUrl,
  name,
  descriptionText,
  placeSlug,
  resizedImageUrls,
}: Props) => {
  const imageProportion = { xs: '104px', xl: '120px' }

  return (
    <NextLink
      href={pages['/places/[placeSlug]']({ placeSlug })}
      color="common.black"
      underline="none"
    >
      <Stack
        direction="row"
        spacing={{ xs: 1.5, xl: 2 }}
        alignItems="center"
        width={{ xs: 260, sm: 254, lg: 'auto' }}
      >
        <Box
          height={imageProportion}
          width={imageProportion}
          sx={{
            flexShrink: 0,
            '& .mainImage': {
              borderRadius: { xs: '8px', xl: '11px' },
            },
          }}
          position="relative"
        >
          <ResponsiveImage
            alt={name}
            originalSizeUrl={imageUrl}
            className="mainImage"
            srcSet={{ MOBILE: resizedImageUrls.SMALL }}
          />
        </Box>
        <Stack spacing={{ xs: 0.25 }} sx={{ overflow: 'hidden' }}>
          <Typography
            component="div"
            variant="h3ExtraBold"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
          <Typography variant="bodyRegular">{descriptionText}</Typography>
        </Stack>
      </Stack>
    </NextLink>
  )
}
