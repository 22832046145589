
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { getEventsNearby, getExploreBanner, getExplorePlaces, getFeaturedArticles, } from 'src/api';
import { decodeApplicationLocale } from 'src/app/locale';
import { ExplorePage } from 'src/modules/explore/page';
const getServerSideProps: GetServerSideProps = async ({ locale }) => {
    const input = {
        locale: decodeApplicationLocale(locale ?? ''),
    };
    const paramsWithCoordinates = { ...input, coordinates: null };
    const [banner, places, eventsNearby, featuredArticles] = await Promise.all([
        getExploreBanner(input),
        getExplorePlaces(paramsWithCoordinates),
        getEventsNearby(paramsWithCoordinates),
        getFeaturedArticles(input),
    ]);
    return {
        props: {
            banner,
            places,
            eventsNearby,
            featuredArticles,
        },
    };
};
const Explore = ({ banner, places, eventsNearby, featuredArticles, }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    return (<ExplorePage banner={banner} places={places} eventsNearby={eventsNearby} featuredArticles={featuredArticles}/>);
};
export default Explore;

    async function __Next_Translate__getServerSideProps__18d6016c9c3__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18d6016c9c3__ as getServerSideProps }
  