import { Box, Stack, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { isEmpty } from 'fp-ts/lib/Array'
import { IEvent } from 'src/api'
import {
  eventCardSpacing,
  sectionHeaderContentSpacing,
} from 'src/layouts/spacing'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'
import { EventCardFindTickets } from 'src/modules/event/card'

type Props = {
  events: Array<IEvent>
}

export const RecommendedEvents = ({ events }: Props) => {
  const { t } = useTranslation()

  if (isEmpty(events)) {
    return null
  }

  return (
    <Stack spacing={sectionHeaderContentSpacing}>
      <Typography variant="h2Black">{t('especially_for_you')}</Typography>
      <Box>
        <Stack
          sx={scrollableRowStyles}
          direction="row"
          spacing={eventCardSpacing}
        >
          {events.map((event) => (
            <EventCardFindTickets event={event} key={event.eventId} />
          ))}
        </Stack>
      </Box>
    </Stack>
  )
}
