import { Box, ResponsiveStyleValue } from '@mui/system'
import { Container } from '@mui/material'
import { ReactNode } from 'react'

const defaultGradientHeight = {
  xs: '235px',
  sm: '298px',
  lg: '428px',
  xl: '540px',
}

const getContainerMarginTop = (gradientHeight: ResponsiveStyleValue<string>) =>
  Object.entries(gradientHeight).reduce((result, [key, value]) => {
    return { ...result, [key]: `-${value}` }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as Record<string, string>)

export const headerImageHeight = {
  xs: '249px',
  sm: '344px',
  lg: '472px',
  xl: '526px',
}

export const headerImageBorderRadius = { xs: '10px', lg: '12px' }

type Props = {
  gradient: string
  children: ReactNode
  gradientHeight?: ResponsiveStyleValue<string>
  paddingTopXl?: number
  paddingTop?: ResponsiveStyleValue<number>
  decors?: ReactNode
}

export const HeaderGradient = ({
  gradient,
  children,
  gradientHeight,
  paddingTopXl,
  paddingTop,
  decors,
}: Props) => {
  const gradientHeightValue = gradientHeight ?? defaultGradientHeight
  const containerMarginTop = getContainerMarginTop(gradientHeightValue)

  return (
    <>
      <Box
        height={gradientHeightValue}
        sx={{ background: gradient, position: 'relative', overflowX: 'hidden' }}
      >
        {decors}
      </Box>
      <Container
        maxWidth="md"
        sx={{
          mt: containerMarginTop,
          pt: paddingTop ?? { xs: 4, sm: 5, xl: paddingTopXl },
          position: 'relative',
        }}
      >
        {children}
      </Container>
    </>
  )
}
